.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 0 10px;
}

.item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.link,
.text {
  color: var(--grey-2);
  line-height: 1.5;
  transition: color var(--transition);
}

.link:hover {
  color: var(--color-black-primary);
}

.icon {
  margin-left: 10px;
  line-height: 0;
  color: var(--grey-2);
}

.input {
  height: 30px;
  padding: 4px 12px;
  font-size: 0.812rem;
  box-shadow: var(--box-shadow-a);
  border-radius: var(--border-radius-m);
}

.input::placeholder {
  color: var(--color-black-primary);
}

@media (max-width: 575px) {
  .list {
    gap: 0 6px;
  }

  .icon {
    margin-left: 6px;
  }

  .icon svg {
    width: 10px;
  }
}
